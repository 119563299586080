import * as React from "react";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import ActivationPageFooter from "./components/Footer/ActivationPageFooter";
import ActivationPageHeader from "./components/ActivationPageHeader";
import ActivationPageWrapper from "./components/ActivationPageWrapper";
import ActivationFormContainer from "./components/main/ActivationForm/ActivationFormContainer";
import ActivationPageMainContainer from "./components/main/ActivationPageMainContainer";
import ActivationPageSidebar from "./components/sidebar/ActivationPageSidebar";
import SidebarInfoBlock from "./components/sidebar/SidebarInfoBlock";
import SidebarInfoBlockContent from "./components/sidebar/SidebarInfoBlockContent";
import SidebarIntroBlock from "./components/sidebar/SidebarIntroBlock";
import SidebarSubscription from "./components/sidebar/SidebarSubscription";
import i18n from "i18next";
import SidebarCloudAccountContent from "./components/sidebar/SidebarCloudAccountContent";
import {themeOn} from "./config/apiConfig";

const ActivationPage: React.FC<{}> = () => {
  const curLang = document.documentElement.lang;
  React.useEffect(() => {
    if (curLang !== i18n.language) {
      document.documentElement.lang = i18n.language
    }
  },[curLang]);
  const {t} = useTranslation();

  return (
    <div data-testid="page">
      <ActivationPageHeader
        wrapperClass="brandingBar brandingHeaderColor"
        logo="oLogo"
        textClass="oLogoWords title7"
        text={t('common.cloud', "Cloud")}
      />
      <ActivationPageWrapper>
        <ActivationPageSidebar>
          <SidebarInfoBlock wrapperClass="promoContainer" >
              <SidebarCloudAccountContent />
              <SidebarInfoBlockContent
                  title={t('infoBlock.titleThree', 'About Region Group')}
                  content={t('infoBlock.contentThree', 'A Region Group (also known as Realm) is a logical group of one or more OCI regions that are subject to a common set of rules for data residency, sovereignty, and governance. Region groups in OCI are also known as realms.')}
                  hide={themeOn === true}
              />
              <SidebarInfoBlockContent
                title={t('infoBlock.titleTwo', "About Regions")}
                content={<Trans i18nKey="infoBlock.contentTwo">Your <a href="https://docs.cloud.nricloud.jp/en-us/iaas/Content/Identity/Tasks/managingregions.htm?Highlight=home%20region#The" target='_blank' rel="noopener noreferrer">Home Region</a> is the geographic location where your cloud account and identity resources will be created. It is not changeable after sign-up. For SaaS application(s) the Home Region does not indicate the provisioning location. Your SaaS application(s) will be provisioned in the geo-region specified on your order. After creating a cloud account to add your subscription, the Home Region is where your primary Identity Domain will be located. Depending on SaaS application the application user credentials may also be stored at the same Home Region Identity Domain location.</Trans>}
              />
          </SidebarInfoBlock>
          <SidebarIntroBlock wrapperClass="progressContainer" >
            <SidebarSubscription title={t('subscriptionBlock.title', "Your Subscriptions")} />
          </SidebarIntroBlock>
        </ActivationPageSidebar>
        <ActivationPageMainContainer>
          <ActivationFormContainer />
        </ActivationPageMainContainer>
      </ActivationPageWrapper>
      <ActivationPageFooter />
    </div>
  );
};

export default withTranslation()(ActivationPage);
